@import './media-queries.scss';

.standard {
  h1,
  h2,
  h3 {
    // font-family: 'Playfair Display', serif;
    font-family: 'Raleway', sans-serif!important;

  }

  h4,
  h5,
  p,
  li,
  button {
    // font-family: 'Montserrat', sans-serif;
    font-family: 'Raleway', sans-serif!important;

  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li {
    margin: 0;
  }

  button {
    cursor: pointer;
  }
  &__edit-budget{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    h5{
      position: relative;
      left:0;
      text-align: left;
      padding-bottom: 10px;
    }
    :where(.css-dev-only-do-not-override-1s45jgs).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      margin-right: 20px;
    }
    Button{
      margin-left: 20px;
    }
  }
  &__link {
    text-decoration: none;
    color: initial;
    cursor: pointer;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
  }

  &__field-box {
    position: relative;
    margin: 20px 0;
  }

  &__field {
    border-radius: 4px;
    border: solid 1px #79747E;
    height: 48px;
    padding: 6px 0 0;
    text-indent: 10px;
    width: 100%;
  }
  &__bold{
    font-size: 14px;
  }
  &__password-input{
    position: relative;
  }
  &__eye-icon{
    width:18px;
    height:auto;
    position: absolute;
    left:95%;
    top:20px;
    cursor: pointer;
  }
  &__field:focus {
    outline: none;
    background-color: white;
  }
  &__notification-icon{
    width:20px;
  }
  &__label {
    font-family: Roboto;
    font-size: 12px;
    padding: 0 5px;
    z-index: 3;
    position: absolute;
    top: -7px;
    left: 15px;

    background-color: #ffff;
  }

  input:-internal-autofill-selected {
    background-color: white !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__right-side {
    padding: 40px 5%;
    width: 100%;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  &__add-button {
    position:relative;
    right:40px;
    z-index: 5;
  }


  &__title {
    text-align: left;
    padding-bottom: 5px;
  }

  &__title-underline {
    background-color: #007B70;
    height: 2px;
    width: 160px;
  }
  &__content{
    margin-top: 100px;
  }
  &__unread{
    background-color: #e6fffd;
  }
  .ant-table-wrapper .ant-table-pagination-right {
    justify-content: center;
  }
&__checked{
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
&__checked-icon{
  width:20px;
  height:20px;
  margin-left: 10px;
}

  //modals style
  &__close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__modal-title {
    padding: 20px 0;
    font-size: 20px;
  }

  &__modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

  }

  &__modal-button {
    width: 172px;
    height: 40px;
    border-radius: 100px;
    color: #fff;
    border: none;
    font-weight: bold;
    margin-left: 20px;
  }

  &__submit {
    background: #007B70;
    ;
    color: #fff;
  }

  &__cancel {
    background-color: #fff;
    color: red;
    border: 1px solid red;
    width: 120px;
  }
  &__website-description{
    font-size: 16px;
    padding:10px 0;
  }

  &__modal-field-box {
    margin: 30px 0;
  }

  &__modal-indication {
    font-weight: 500;
    font-size: 12px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    margin-top: 15px;
    margin-left: 30px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #007B70;


;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #007B70;


;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  &__back{
    display: flex;
    flex-direction: row;
    position:relative;
    bottom:20px;
    img{
      width:20px;
      height:auto;
      margin-right: 3px;
    }
    h3{
      font-size: 16px;
    }
  }
  &__select-field{
    width:100%!important;
    height:40px;
    border-radius: 4px;
    border: solid 1px #79747E;
    height: 48px;
    padding: 6px 0 0;
    text-indent: 10px;
    width: 100%;
    .ant-select-selector{
      border:none!important;
    }
  }
  &__only-desktop{
    display: none;
  }
  @include tablet {
    &__footer-box {
      padding: 0 25%;
    }
  }

  @include desktop {
    &__only-desktop{
      display: initial;
    }
  }
}

// body {
//   overflow: auto;
//   font-size: calc(15px + 0.390625vw);

//   h1 {
//     font-size: 1.8rem;
//   }

//   h2 {
//     font-size: 1.5rem;
//   }

//   h3 {
//     font-size: 1.3rem;
//   }

//   h4 {
//     font-size: 1.1rem;
//   }

//   h5 {
//     font-size: 0.9rem;
//   }

//   h6 {
//     font-size: 0.7rem;
//   }
// }

._loading_overlay_overlay {
  height: 100vh;
}
